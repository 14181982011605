import {
  durationOptions,
  guaranteedGainsPromo,
} from "../../Promo/Constants/Promos"
import {
  addDays,
  addHours,
  addMonths,
  addWeeks,
  isAfter,
  isBefore,
} from "date-fns"
import { OrderStatusStates, PartialRefundSteps, PovStates } from "./constants"
import { ILinkDetails } from "../../../../pages/order-status/[uuid]"
import { IPhoto } from "../../../../utils/photos"

interface IGetPromoFulfillmentDueDate {
  promoStartDate: Date | undefined
  duration: string | undefined
  durationType: string | undefined
  isExtensionEligible: boolean
}

export const getPromoFulfillmentDueDate = (
  props: IGetPromoFulfillmentDueDate
): Date | undefined => {
  const { isExtensionEligible, promoStartDate, duration, durationType } = props
  const numSteps = Number(duration)
  if (
    !isExtensionEligible ||
    !promoStartDate ||
    !duration ||
    !durationType ||
    durationType === durationOptions.openEnded ||
    durationType === durationOptions.permanent
  ) {
    return undefined
  }
  switch (durationType) {
    case durationOptions.hours:
      return addHours(promoStartDate, numSteps)
    case durationOptions.days:
      return addDays(promoStartDate, numSteps)
    case durationOptions.weeks:
      return addWeeks(promoStartDate, numSteps)
    case durationOptions.months:
      return addMonths(promoStartDate, numSteps)
    default:
      return addWeeks(promoStartDate, 54)
  }
}

export interface ITimeboxedProgress {
  updatedAt: string
  claimCount: number
  claimMax: number
  isCompleted: boolean
}

export interface IGetTimeboxDetails {
  hasExtensionData: boolean
  isExtensionEligible: boolean
  dueDate: Date | undefined
  startDate: Date | undefined
  progress: ITimeboxedProgress
  evidence: IPhoto[]
}

interface IGetTimeboxDetailsProps {
  promoStartDate: Date | undefined
  promoType: string
  duration: string | undefined
  durationType: string | undefined
  link: string | undefined
  linkDetails: ILinkDetails | undefined
  claimMax: string | undefined
  gainsClaimed?: number
  evidence?: IPhoto[]
}

export const getTimeboxDetails = ({
  promoStartDate,
  promoType,
  duration,
  durationType,
  link,
  linkDetails,
  claimMax,
  gainsClaimed,
  evidence,
}: IGetTimeboxDetailsProps): IGetTimeboxDetails => {
  const isExtensionEligible = promoType === guaranteedGainsPromo.type
  const hasExtensionData =
    isExtensionEligible && Boolean(link) && Boolean(linkDetails?.updatedAt)
  const extensionClaimCount = linkDetails?.claimCount
    ? Number(linkDetails.claimCount)
    : undefined
  const isCompleted =
    Boolean(claimMax) &&
    extensionClaimCount !== undefined &&
    extensionClaimCount >= Number(claimMax)
  const claimCount = isCompleted
    ? Number(claimMax)
    : extensionClaimCount
    ? extensionClaimCount
    : Number(gainsClaimed) && gainsClaimed !== undefined
    ? gainsClaimed
    : 0

  return {
    hasExtensionData,
    isExtensionEligible,
    evidence: evidence || [],
    progress: {
      updatedAt: linkDetails?.updatedAt ?? "",
      claimCount,
      claimMax: claimMax ? Number(claimMax) : 0,
      isCompleted,
    },
    startDate: promoStartDate,
    dueDate: getPromoFulfillmentDueDate({
      isExtensionEligible,
      promoStartDate,
      duration,
      durationType,
    }),
  }
}

export interface IPovStates {
  isBuyer: boolean
  isSeller: boolean
  perspective: PovStates
}

export const getPov = (state: PovStates): IPovStates => {
  return {
    isBuyer: state === PovStates.buyer,
    isSeller: state === PovStates.seller,
    perspective: state,
  }
}

export interface IPartialRefundStep {
  state: PartialRefundSteps
  isInitiated: boolean
  isInProgress: boolean
  isManualEntry: boolean
  isSubmitting: boolean
  isCompleted: boolean
}

export const getPartialRefundStepState = (
  state: PartialRefundSteps
): IPartialRefundStep => {
  return {
    state: state,
    isInitiated: state === PartialRefundSteps.Initiated,
    isInProgress: state === PartialRefundSteps.InProgress,
    isManualEntry: state === PartialRefundSteps.ManualEntry,
    isSubmitting: state === PartialRefundSteps.Submitting,
    isCompleted: state === PartialRefundSteps.Completed,
  }
}

export interface IOrderStatusStep {
  state: OrderStatusStates
  updatedAt: Date
  isPending: boolean
  isAccepted: boolean
  isInProgress: boolean
  isCompleted: boolean
  isSellerCompleted: boolean
  isExpired: boolean
  isDisputed: boolean
  isPartialRefund: boolean
  isUnderReview: boolean
  isReviewed: boolean
}

export const getOrderStatusStepHelper = (
  state: OrderStatusStates,
  updatedAt: Date
): IOrderStatusStep => {
  return {
    state: state,
    isPending: state === OrderStatusStates.Pending,
    isAccepted: state === OrderStatusStates.Accepted,
    isInProgress: state === OrderStatusStates.InProgress,
    isCompleted: state === OrderStatusStates.Completed,
    isSellerCompleted: state === OrderStatusStates.SellerCompleted,
    isExpired: state === OrderStatusStates.Expired,
    isDisputed: state === OrderStatusStates.Disputed,
    isPartialRefund: state === OrderStatusStates.PartialRefund,
    isUnderReview: state === OrderStatusStates.UnderReview,
    isReviewed: state === OrderStatusStates.Reviewed,
    updatedAt: updatedAt,
  }
}

export const getOrderStatusStep = (
  statusState: OrderStatusStates,
  timeboxDetails: IGetTimeboxDetails,
  latestUpdate: Date
): IOrderStatusStep => {
  const now = new Date()
  if (!timeboxDetails.isExtensionEligible) {
    return getOrderStatusStepHelper(statusState, latestUpdate)
  }
  switch (statusState) {
    case OrderStatusStates.Accepted:
      if (
        !timeboxDetails.dueDate &&
        timeboxDetails.startDate &&
        isAfter(now, timeboxDetails.startDate)
      ) {
        return getOrderStatusStepHelper(
          OrderStatusStates.InProgress,
          latestUpdate
        )
      }
      if (
        timeboxDetails.startDate &&
        isAfter(now, timeboxDetails.startDate) &&
        timeboxDetails.dueDate &&
        isBefore(now, timeboxDetails.dueDate)
      ) {
        return getOrderStatusStepHelper(
          OrderStatusStates.InProgress,
          latestUpdate
        )
      }
      if (timeboxDetails.dueDate && now > timeboxDetails.dueDate) {
        return getOrderStatusStepHelper(OrderStatusStates.Expired, latestUpdate)
      }
      return getOrderStatusStepHelper(statusState, latestUpdate)
    case OrderStatusStates.Disputed:
      return getOrderStatusStepHelper(
        OrderStatusStates.UnderReview,
        latestUpdate
      )
  }
  return getOrderStatusStepHelper(statusState, latestUpdate)
}
export const getDurationTypeLabel = (
  duration: number,
  type: string,
  lowercase: boolean
): string => {
  const result = { value: lowercase ? type.toLowerCase() : type }
  return duration <= 1 ? result.value.slice(0, -1) : result.value
}
