import { getDurationTypeLabel } from "../../Order/Status/utils"

interface IPromoMap {
  [key: string]: { promoTypes: IPromoType[] }
}

export interface IPromoType {
  type: string
  quantity: string
  values: number[]
  durationValues: string[]
  description: string
  completion?: string
  descriptionInstructions?: string
  placeholder: string
  suggestedPrice: string
  quantityType: string
  getDurationDescription: (
    duration: number,
    duration_type: string,
    useShorthand: boolean
  ) => string
}

const noEndDate = (duration: number, duration_type: string): boolean => {
  return (
    Number.isNaN(duration) ||
    !duration_type ||
    duration_type === durationOptions.permanent ||
    duration_type === durationOptions.openEnded
  )
}

export const durationDescription = (
  useShorthand: boolean,
  noDurationText: string,
  durationText: string,
  shorthandText: string,
  duration_type: string,
  duration: number,
  toLower = false
): string => {
  const isLegacyOrNoEndDate = noEndDate(duration, duration_type)
  if (useShorthand) {
    if (isLegacyOrNoEndDate) {
      return shorthandText
    }
    return `${duration} ${getDurationTypeLabel(
      duration,
      duration_type,
      toLower
    )}`
  }
  if (isLegacyOrNoEndDate) {
    return noDurationText
  }
  return `${durationText} ${duration} ${getDurationTypeLabel(
    duration,
    duration_type,
    toLower
  )}`
}

export const durationOptions = {
  hours: "Hours",
  days: "Days",
  weeks: "Weeks",
  months: "Months",
  openEnded: "Open Ended",
  permanent: "Permanent",
}

export const guaranteedGainsPromo: IPromoType = {
  type: "Guaranteed Gains",
  quantity: "No. of Gains",
  quantityType: "Gains",
  values: [100, 300, 500],
  durationValues: [
    durationOptions.days,
    durationOptions.weeks,
    durationOptions.months,
    durationOptions.openEnded,
  ],
  descriptionInstructions:
    "Describe your promo! Include important information such as the platforms and promo methods used to fulfill the gains.",
  description:
    "Promote the buyer using their media, captions, and platform link until they reach a set number of subscribers.",
  completion:
    "This promo is complete when the total number of subscribers (gains) has been fulfilled or when the set length of time has been reached.",
  placeholder: "100 Guaranteed Gains",
  suggestedPrice: "100.00",
  getDurationDescription: (
    duration: number,
    duration_type: string,
    useShorthand
  ): string =>
    durationDescription(
      useShorthand,
      "Gains fulfilled with no time limit",
      "Gains fulfilled within",
      "No time limit",
      duration_type,
      duration
    ),
}
const pinnedPostPromo: IPromoType = {
  type: "Pinned Post",
  quantity: "Duration - Hours/Days",
  quantityType: "Pinned Post",
  durationValues: [
    durationOptions.hours,
    durationOptions.days,
    durationOptions.weeks,
    durationOptions.months,
  ],
  values: [24, 48, 72],
  descriptionInstructions:
    "Describe your promo! Include important information such as the average engagement your pinned posts typically receive.",
  description:
    "Create a post with text, images, and video that can be customized by the buyer. The seller will pin this post to the top of their OF profile for a specified duration to encourage their subscribers to engage with the buyer’s profile.",
  placeholder: "24 Hour Pinned Post",
  suggestedPrice: "100.00",
  getDurationDescription: (
    duration: number,
    duration_type: string,
    useShorthand
  ): string =>
    durationDescription(
      useShorthand,
      "Pinned on feed permanently",
      "Pinned on feed for",
      "Permanent",
      duration_type,
      duration
    ),
}
const massDMPromo: IPromoType = {
  type: "Mass DM",
  quantity: "No. of DMs",
  quantityType: "Messages",
  durationValues: [
    durationOptions.hours,
    durationOptions.days,
    durationOptions.weeks,
    durationOptions.months,
    durationOptions.permanent,
  ],
  values: [100, 300, 500],
  descriptionInstructions:
    "Describe your promo! Include important information such as the size and specific audience receiving the DM.",
  description:
    "Send a mass direct message to a specified audience displaying the buyer's media, captions, and/or links.",
  placeholder: "100 Follower Mass DM",
  suggestedPrice: "100.00",
  getDurationDescription: (
    duration: number,
    duration_type: string,
    useShorthand
  ): string =>
    durationDescription(
      useShorthand,
      "Messages are never deleted",
      "Messages are deleted after",
      "Permanent",
      duration_type,
      duration
    ),
}
const feedPostPromo: IPromoType = {
  type: "Feed Post",
  quantity: "No. of Feed Posts",
  quantityType: "Posts",
  durationValues: [
    durationOptions.hours,
    durationOptions.days,
    durationOptions.weeks,
    durationOptions.months,
    durationOptions.permanent,
  ],
  values: [1, 2, 3],
  descriptionInstructions:
    "Be promoted on my page with over 100k monthly views and many big tippers!",
  description:
    "Post on your feed using text, images, or video promoting the buyer's profile to all of your subscribers.",
  placeholder: "1 Feed Post",
  suggestedPrice: "100.00",
  getDurationDescription: (
    duration: number,
    duration_type: string,
    useShorthand
  ): string =>
    durationDescription(
      useShorthand,
      "Posts stay on feed permanently",
      "Posts stay on feed for",
      "Permanent",
      duration_type,
      duration
    ),
}
const repostPromo: IPromoType = {
  type: "Repost",
  quantity: "No. of Reposts",
  quantityType: "Reposts",
  durationValues: [
    durationOptions.hours,
    durationOptions.days,
    durationOptions.weeks,
    durationOptions.months,
    durationOptions.permanent,
  ],
  values: [1, 3, 5],
  descriptionInstructions:
    "Describe your promo! Include important information such as the average engagement your reposts typically receive.",
  description:
    "The seller will Repost a post of the buyer’s choosing, with the option to also include their own text on the Repost.",
  placeholder: "1 Repost",
  suggestedPrice: "100.00",
  getDurationDescription: (
    duration: number,
    duration_type: string,
    useShorthand
  ): string =>
    durationDescription(
      useShorthand,
      "Posts stay on feed permanently",
      "Reposts stay on feed for",
      "Permanent",
      duration_type,
      duration
    ),
}
const storyPostPromo: IPromoType = {
  type: "Story Post",
  quantity: "No. of Story Posts",
  quantityType: "Stories",
  values: [1, 2, 3],
  durationValues: [
    durationOptions.hours,
    durationOptions.days,
    durationOptions.weeks,
    durationOptions.months,
  ],
  descriptionInstructions:
    "Describe your promo! Include important information such as the average number of views your stories typically receive.",
  description:
    "Post and tag the buyer in your story using text, images, or video to promote the buyer to your subscribers.",
  placeholder: "1 Story Post",
  suggestedPrice: "100.00",
  getDurationDescription: (
    duration: number,
    duration_type: string,
    useShorthand
  ): string =>
    durationDescription(
      useShorthand,
      "Promo runs permanently",
      "Stories posted for",
      "Permanent",
      duration_type,
      duration
    ),
}
export const serviceProviderPromo: IPromoType = {
  type: "Professional Services",
  quantity: "",
  quantityType: "",
  values: [1, 2, 3],
  durationValues: [],
  descriptionInstructions:
    "Provide your professional services in a field based on your expertise. This could range from photography/videography to hair/makeup.",
  description:
    "Communicate with the each other prior to planning and purchasing this promo. Then complete the in-person collaboration by meeting all expectations laid out for this professional service.",
  placeholder: "1 Story Post",
  suggestedPrice: "100.00",
  getDurationDescription: (
    duration: number,
    duration_type: string,
    useShorthand
  ): string =>
    durationDescription(
      useShorthand,
      "Promo runs permanently",
      "Stories posted for",
      "Permanent",
      duration_type,
      duration
    ),
}
const mentionPromo: IPromoType = {
  type: "Mention",
  quantity: "No. of Mentions",
  quantityType: "Mentions",
  values: [1, 3, 5],
  durationValues: [
    durationOptions.hours,
    durationOptions.days,
    durationOptions.weeks,
    durationOptions.months,
    durationOptions.permanent,
  ],
  descriptionInstructions:
    "Describe your promo! Include important information such as the average engagement your videos typically receive.",
  description:
    "Tag the buyer's Youtube channel in a video description or title.",
  placeholder: "1 Mention",
  suggestedPrice: "100.00",
  getDurationDescription: (
    duration: number,
    duration_type: string,
    useShorthand
  ): string =>
    durationDescription(
      useShorthand,
      "Mention posted permanently",
      "Mention posted for",
      "Permanent",
      duration_type,
      duration
    ),
}
const shortPromo: IPromoType = {
  type: "Short",
  quantity: "No. of Shorts",
  quantityType: "Shorts",
  values: [1, 3, 5],
  durationValues: [
    durationOptions.hours,
    durationOptions.days,
    durationOptions.weeks,
    durationOptions.months,
    durationOptions.permanent,
  ],
  descriptionInstructions:
    "Describe your promo! Include important information such as the average engatement your Shorts typically receive.",
  description:
    "Create a Youtube Short containing the media, captions, and/or links provided by the buyer.",
  placeholder: "1 Short",
  suggestedPrice: "100.00",
  getDurationDescription: (
    duration: number,
    duration_type: string,
    useShorthand
  ): string =>
    durationDescription(
      useShorthand,
      "Short posted permanently",
      "Short posted for",
      "Permanent",
      duration_type,
      duration
    ),
}
const snapFollowersPromo: IPromoType = {
  type: "Snap to Followers",
  quantity: "No. of Snaps",
  quantityType: "Snaps",
  values: [1, 3, 5],
  durationValues: [
    durationOptions.hours,
    durationOptions.days,
    durationOptions.weeks,
    durationOptions.months,
  ],
  description:
    "Send a snap to your followers using the buyer's images, text and tagged Snapchat handle or custom link.",
  placeholder: "1 Snap to Followers",
  suggestedPrice: "100.00",
  getDurationDescription: (
    duration: number,
    duration_type: string,
    useShorthand
  ): string =>
    durationDescription(
      useShorthand,
      "Promo runs permanently",
      "Promo runs for",
      "Permanent",
      duration_type,
      duration
    ),
}
const subredditPostPromo: IPromoType = {
  type: "Subreddit Post",
  quantity: "No. of Posts",
  quantityType: "Posts",
  values: [1, 3, 5],
  durationValues: [
    durationOptions.hours,
    durationOptions.days,
    durationOptions.weeks,
    durationOptions.months,
    durationOptions.permanent,
  ],
  description:
    "Create a subreddit post with text, images, and video that can be customized by the buyer. The seller must have permission to post on the subreddit that the buyer specifies.",
  placeholder: "1 Post on Subreddit",
  suggestedPrice: "100.00",
  getDurationDescription: (
    duration: number,
    duration_type: string,
    useShorthand
  ): string =>
    durationDescription(
      useShorthand,
      "Promo runs permanently",
      "Promo runs for",
      "Permanent",
      duration_type,
      duration
    ),
}
const storySharePromo: IPromoType = {
  type: "Story Share",
  quantity: "No. of Shares",
  quantityType: "Shares",
  values: [1, 3, 5],
  durationValues: [
    durationOptions.hours,
    durationOptions.days,
    durationOptions.weeks,
    durationOptions.months,
  ],
  descriptionInstructions:
    "Describe your promo! Include important information such as the average number of views your stories typically receive.",
  description:
    "Re-share the buyer’s Instagram post or story on your own 24 hour story.",
  placeholder: "1 Story Share",
  suggestedPrice: "100.00",
  getDurationDescription: (
    duration: number,
    duration_type: string,
    useShorthand
  ): string =>
    durationDescription(
      useShorthand,
      "Promo runs permanently",
      "Story shared for",
      "Permanent",
      duration_type,
      duration
    ),
}
const reelPostPromo: IPromoType = {
  type: "Reel Post",
  quantity: "No. of Reels",
  quantityType: "Reels",
  values: [1, 3, 5],
  durationValues: [
    durationOptions.hours,
    durationOptions.days,
    durationOptions.weeks,
    durationOptions.months,
    durationOptions.permanent,
  ],
  descriptionInstructions:
    "Describe your promo! Include important information such as the average engagement your reels typically receive.",
  description:
    "Create an Instagram Reel promoting the buyer’s profile using custom images, videos, and text.",
  placeholder: "1 Reel",
  suggestedPrice: "100.00",
  getDurationDescription: (
    duration: number,
    duration_type: string,
    useShorthand
  ): string =>
    durationDescription(
      useShorthand,
      "Reels stay on feed permanently",
      "Reels stay on feed for",
      "Permanent",
      duration_type,
      duration
    ),
}
export const assistantServicesPromo: IPromoType = {
  type: "Assistant Services",
  quantity: "Duration - Weeks/Months",
  quantityType: "Drops/Day",
  values: [1, 3, 5],
  durationValues: [
    durationOptions.hours,
    durationOptions.days,
    durationOptions.weeks,
    durationOptions.months,
    durationOptions.permanent,
  ],
  descriptionInstructions:
    "Describe your promo! Include important information such as the number and size of groups and specific days of the week if applicable.",
  description:
    'Provide assistance on Twitter/X by sharing the buyer\'s username in a set number of retweet groups at specified intervals (""drops""). \n' +
    "The assistance must be conducted from your own account, do not request login information from the buyer.",
  placeholder: "1 Month of Assistant Services",
  suggestedPrice: "100.00",
  getDurationDescription: (
    duration: number,
    duration_type: string,
    useShorthand
  ): string =>
    durationDescription(
      useShorthand,
      "Services have no time limit",
      "Services for",
      "No time limit",
      duration_type,
      duration
    ),
}
const friendSpotPromo: IPromoType = {
  type: "Friend Spot",
  quantity: "No. of Friend Spots",
  quantityType: "Spots",
  durationValues: [
    durationOptions.hours,
    durationOptions.days,
    durationOptions.weeks,
    durationOptions.months,
    durationOptions.permanent,
  ],
  values: [1, 2, 3],
  descriptionInstructions:
    "Describe your promo! Include relevant information such as the size of your public friends list on OnlyFans!",
  description:
    "Add the buyer’s OnlyFans page as a friend to be displayed on the public friends list.",
  placeholder: "1 Friend Spot",
  suggestedPrice: "100.00",
  getDurationDescription: (
    duration: number,
    duration_type: string,
    useShorthand
  ): string =>
    durationDescription(
      useShorthand,
      "Permanent Friend Spot",
      "Stay in Friend Spot for",
      "Permanent",
      duration_type,
      duration
    ),
}

export const getPromoType = (
  brand?: string,
  promoTitle?: string
): IPromoType | undefined => {
  if (!brand || !promoTitle) {
    return undefined
  }
  return Promos[brand.toLowerCase()]?.promoTypes.find(
    (x) => x.type === promoTitle
  )
}

const Promos: IPromoMap = {
  "onlyfans": {
    promoTypes: [
      pinnedPostPromo,
      guaranteedGainsPromo,
      massDMPromo,
      feedPostPromo,
      storyPostPromo,
      friendSpotPromo,
    ],
  },
  "x/twitter": {
    promoTypes: [
      {
        ...pinnedPostPromo,
        durationValues: [
          ...pinnedPostPromo.durationValues,
          durationOptions.permanent,
        ],
      },
      repostPromo,
      feedPostPromo,
      assistantServicesPromo,
      guaranteedGainsPromo,
    ],
  },
  "instagram": {
    promoTypes: [
      storyPostPromo,
      storySharePromo,
      feedPostPromo,
      reelPostPromo,
      {
        ...pinnedPostPromo,
        durationValues: [
          ...pinnedPostPromo.durationValues,
          durationOptions.permanent,
        ],
      },
      guaranteedGainsPromo,
    ],
  },
  "tiktok": {
    promoTypes: [
      feedPostPromo,
      repostPromo,
      {
        ...pinnedPostPromo,
        durationValues: [
          ...pinnedPostPromo.durationValues,
          durationOptions.permanent,
        ],
      },
    ],
  },
  "reddit": {
    promoTypes: [
      subredditPostPromo,
      feedPostPromo,
      {
        ...pinnedPostPromo,
        durationValues: [
          ...pinnedPostPromo.durationValues,
          durationOptions.permanent,
        ],
      },
      guaranteedGainsPromo,
    ],
  },
  "facebook": {
    promoTypes: [feedPostPromo, pinnedPostPromo, storyPostPromo],
  },
  "snapchat": {
    promoTypes: [snapFollowersPromo, storyPostPromo],
  },
  "fansly": {
    promoTypes: [
      pinnedPostPromo,
      guaranteedGainsPromo,
      massDMPromo,
      feedPostPromo,
      storyPostPromo,
    ],
  },
  "youtube": {
    promoTypes: [mentionPromo, shortPromo, feedPostPromo],
  },
  "manyvids": {
    promoTypes: [repostPromo, guaranteedGainsPromo, massDMPromo, feedPostPromo],
  },
  "service provider": {
    promoTypes: [serviceProviderPromo],
  },
  "bluesky": {
    promoTypes: [pinnedPostPromo, repostPromo, feedPostPromo],
  },
}
