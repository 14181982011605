import React from "react"
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  alpha,
  Box,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import {
  IPromoTier,
  IPromoTierPayload,
} from "../../Molecules/Promo/CreatePromo"
import {
  assistantServicesPromo,
  durationOptions,
  getPromoType,
} from "./Constants/Promos"
import { PromoDuration } from "../../Molecules/Promo/PromoPreview"

interface IPackageRadioGroup {
  promoPackages: IPromoTier[]
  selectedPackage: IPromoTier
  setSelectedPackage: (newSelectedPackage: IPromoTier) => void
  userProfile?: boolean
  promoTitle: string
  platform: string
}

export const formatDurationType = (
  duration: string,
  durationType: string
): string => {
  return Number(duration) === 1 &&
    durationType !== durationOptions.permanent &&
    durationType !== durationOptions.openEnded
    ? durationType.slice(0, -1)
    : durationType
}

export const formatPackageLabel = (
  packageItem: IPromoTierPayload,
  promoTitle: string,
  platform: string,
  showDuration = false,
  boldStyling = false
): React.JSX.Element => {
  const isAssistedServices = promoTitle === assistantServicesPromo.type
  const shouldPlural = Number(packageItem.quantity) > 1
  const title = isAssistedServices
    ? "Drop" + (shouldPlural ? "s" : "") + " per day"
    : shouldPlural
    ? promoTitle + (promoTitle.endsWith("s") ? "" : "s")
    : promoTitle
  const promoType = getPromoType(platform, promoTitle)
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
      }}
      mb={"-1px"}
    >
      <Typography
        sx={
          boldStyling
            ? {
                lineHeight: "150%",
                fontWeight: 600,
                letterSpacing: "0.15px",
              }
            : {}
        }
      >
        {Number(packageItem.quantity) > 0
          ? `${packageItem.quantity} ${title}`
          : packageItem.description}
      </Typography>
      {promoType && showDuration && platform !== "Service Provider" && (
        <PromoDuration
          useShorthand={true}
          promo={promoType}
          duration={packageItem.duration}
          duration_type={packageItem.duration_type}
        />
      )}
    </Box>
  )
}

const findPackage = (
  value: string,
  packages: IPromoTier[]
): IPromoTier | undefined => {
  return packages.find((p) => p.packageId === value || p.uuid === value)
}

const PackagesRadioGroup = ({
  promoTitle,
  promoPackages,
  selectedPackage,
  setSelectedPackage,
  userProfile = false,
  platform,
}: IPackageRadioGroup): JSX.Element => {
  const theme = useTheme()

  const sortedPackages = React.useMemo(() => {
    return promoPackages.sort((a, b) => Number(b.price) - Number(a.price))
  }, [promoPackages])

  const selectedPackageKey = React.useMemo(() => {
    return (
      selectedPackage.packageId ||
      selectedPackage.uuid ||
      sortedPackages[0].packageId ||
      sortedPackages[0].uuid
    )
  }, [selectedPackage, sortedPackages])

  const handlePackageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentPackage = findPackage(event.target.value, sortedPackages)
    if (!currentPackage) {
      return
    }
    setSelectedPackage(currentPackage)
  }

  return (
    <FormControl fullWidth>
      <RadioGroup value={selectedPackageKey} onChange={handlePackageChange}>
        {sortedPackages.map((pkg) => {
          const packageKey = pkg.packageId || pkg.uuid
          const isSelected = selectedPackageKey === packageKey && !userProfile
          return (
            <Stack
              direction="row"
              key={packageKey}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "8px 0",
                background: isSelected
                  ? alpha(theme.palette.primary.light, 0.1)
                  : "inherit",
              }}
            >
              <FormControlLabel
                sx={{
                  cursor: userProfile ? "default" : "pointer",
                  width: "100%",
                  padding: "0px 8px",
                }}
                value={packageKey}
                control={
                  !userProfile ? <Radio /> : <Box sx={{ width: "10px" }} />
                }
                label={formatPackageLabel(pkg, promoTitle, platform, true)}
              />
              <Typography
                variant="subtitle1"
                sx={{
                  padding: "0px 8px",
                }}
              >
                {Number(pkg.price).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: Number.isInteger(pkg.price) ? 0 : 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Stack>
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}

export default PackagesRadioGroup
