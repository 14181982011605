import styled from "@emotion/styled"
import Image from "next/legacy/image"
import Logo from "../../assets/Icons/FullColor/BlossmLogoPrimaryColor.svg"
import { customLoader } from "../../../utils/imageLoader"
import { Button, Dialog, Typography, useTheme } from "@mui/material"
import { Colors } from "../../../styles/Colors"
import React from "react"

export interface IPromoGuideProps {
  promoGuideOpen: boolean
  close: () => void
  promoGuide?: string
}

export const PromoGuide = ({
  promoGuideOpen,
  promoGuide,
  close,
}: IPromoGuideProps): React.JSX.Element => {
  const theme = useTheme()
  return (
    <Dialog open={promoGuideOpen} onClose={close}>
      {promoGuide === "Guaranteed Gains" && (
        <>
          <DocHeader>
            <Image
              width={24}
              height={30}
              src={Logo.src}
              alt={"BlossmLogoSmall"}
              loader={customLoader}
              objectFit={"fill"}
            />
            <Typography variant={"h6"} fontWeight={"bold"}>
              Guaranteed Gains Guide
            </Typography>
          </DocHeader>
          <DocBody>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What is a Guaranteed Gains promo?
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              This promo is typically done on fansites such as OnlyFans or
              Fansly where the seller promotes the buyer until an agreed-upon
              number of subscribers is reached. For example, 100 GGs means the
              buyer gets 100 new subscribers. The seller promotes the buyer's
              content with their free trial or campaign link, which allows the
              buyer to track the number of subscribers that come directly from
              the seller's promo. The concept is the same for non-paid
              platforms, except the buyer gains followers rather than
              subscribers. The seller can use a variety of promo methods that
              will be most effective for their audiences.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Promo is complete when
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The total number of subscribers (gains) has been fulfilled or when
              the end of the set duration.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Buyer is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing content/media and captions
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              For fansite GGs: Providing a Free Trial Link or Campaign Link (as
              specified by the seller)
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Submitting timely updates to the seller
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Seller is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Clearly stating the link type required for the promo and verifying
              the link when received
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Promoting the buyer through the methods in the promo description
              (if specified).
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Communicating with the buyer to receive updates and new content if
              needed.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What happens if the promo is not completed?
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              When the total number of gains purchased has not been reached by
              the end of the set duration, the buyer may choose one of two
              options:
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Extend the promo until the total number of gains are fulfilled.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Pay for the number of gains that were received within the duration
              (partial refund).
            </Typography>
            <Button
              onClick={() => close()}
              fullWidth
              sx={{
                background: theme.palette.primary.main,
                color: Colors.white,
                borderRadius: "24px",
              }}
            >
              Got it!
            </Button>
          </DocBody>
        </>
      )}
      {promoGuide === "Mass DM" && (
        <>
          <DocHeader>
            <Image
              width={24}
              height={30}
              src={Logo.src}
              alt={"BlossmLogoSmall"}
              loader={customLoader}
              objectFit={"fill"}
            />
            <Typography variant={"h6"} fontWeight={"bold"}>
              Mass DMs Guide
            </Typography>
          </DocHeader>
          <DocBody>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What is a Mass DM promo?
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The seller sends a direct message to their subscribers. Depending
              on the promo description this can mean all of the seller’s
              subscribers or a particular group of their subscribers.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Promo is complete when
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The direct message has been visible to the specified audience for
              the set duration of the promo.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Buyer is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Understanding the promo description of the audience that will
              receive the message.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing content/media and captions.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Communicating with the seller to receive confirmation that the
              message has been sent and remained visible for the amount of time
              specified in the description.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Seller is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Including details regarding the intended audience in the promo
              description.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Sending a direct message to the audience that is specified in the
              description.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing screenshots/recordings to the buyer confirming the
              message has been sent and remained visible for the amount of time
              specified in the description.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What happens if the promo is not completed?
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              If the Mass DM had not been sent for the entire duration or was
              sent to a different audience/number of subscribers than
              advertised, the buyer may dispute the promo.
            </Typography>
            <Button
              onClick={() => close()}
              fullWidth
              sx={{
                background: theme.palette.primary.main,
                color: Colors.white,
                borderRadius: "24px",
              }}
            >
              Got it!
            </Button>
          </DocBody>
        </>
      )}
      {promoGuide === "Pinned Post" && (
        <>
          <DocHeader>
            <Image
              width={24}
              height={30}
              src={Logo.src}
              alt={"BlossmLogoSmall"}
              loader={customLoader}
              objectFit={"fill"}
            />
            <Typography variant={"h6"} fontWeight={"bold"}>
              Pinned Post Guide
            </Typography>
          </DocHeader>
          <DocBody>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What is a Pinned Post promo?
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The seller pins a post with the buyer’s media, captions, and
              links, to the top of their profile for a set amount of time.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Promo is complete when
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The buyer's content has been posted and pinned on the seller’s
              page for the set duration of the promo.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Buyer is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing content/media and captions
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Communicating with the seller to receive confirmation
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Seller is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Notifying the buyer the post has been pinned and/or directing them
              to the post.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing verification that the post was pinned for the entire
              duration of the promo.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What happens if the promo is not completed?
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              If the post had not been pinned for the entire duration or not
              posted to the platform as advertised, the buyer may dispute the
              promo.
            </Typography>
            <Button
              onClick={() => close()}
              fullWidth
              sx={{
                background: theme.palette.primary.main,
                color: Colors.white,
                borderRadius: "24px",
              }}
            >
              Got it!
            </Button>
          </DocBody>
        </>
      )}
      {promoGuide === "Feed Post" && (
        <>
          <DocHeader>
            <Image
              width={24}
              height={30}
              src={Logo.src}
              alt={"BlossmLogoSmall"}
              loader={customLoader}
              objectFit={"fill"}
            />
            <Typography variant={"h6"} fontWeight={"bold"}>
              Feed Post Guide
            </Typography>
          </DocHeader>
          <DocBody>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What is a Feed Post promo?
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The seller posts the buyer's content directly on their feed. This
              can include photos, videos, links, text, or any other
              platform-supported media.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Promo is complete when
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The post has remained on the seller's feed for the set duration of
              the promo.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Buyer is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing content/media, captions, and links.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Communicating with the seller to receive confirmation.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Seller is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Notifying the buyer the post has been made and/or directing them
              to the post.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing verification that the post remained on their feed for
              the entire duration of the promo.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What happens if the promo is not completed?
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              If the post did not remain on the feed for the entire duration of
              the promo or was not posted to the platform as advertised, the
              buyer may dispute the promo.
            </Typography>
            <Button
              onClick={() => close()}
              fullWidth
              sx={{
                background: theme.palette.primary.main,
                color: Colors.white,
                borderRadius: "24px",
              }}
            >
              Got it!
            </Button>
          </DocBody>
        </>
      )}
      {promoGuide === "Story Post" && (
        <>
          <DocHeader>
            <Image
              width={24}
              height={30}
              src={Logo.src}
              alt={"BlossmLogoSmall"}
              loader={customLoader}
              objectFit={"fill"}
            />
            <Typography variant={"h6"} fontWeight={"bold"}>
              Story Post Guide
            </Typography>
          </DocHeader>
          <DocBody>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What is a Story Post promo?
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The seller temporarily features the buyer's content in a story
              post.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Promo is complete when
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The story post has expired after 24 hours (or other agreed upon
              amount of time).
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Buyer is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing content/media, captions, and links.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Communicating with the seller to receive confirmation.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Seller is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Notifying the buyer the post has been made and/or directing them
              to the post.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing verification that the post remained on their feed for
              the entire duration of the promo.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What happens if the promo is not completed?
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              If the story was not visible for the entire duration of the promo
              or was not posted to the platform as advertised, the buyer may
              dispute the promo.
            </Typography>
            <Button
              onClick={() => close()}
              fullWidth
              sx={{
                background: theme.palette.primary.main,
                color: Colors.white,
                borderRadius: "24px",
              }}
            >
              Got it!
            </Button>
          </DocBody>
        </>
      )}
      {promoGuide === "Reel Post" && (
        <>
          <DocHeader>
            <Image
              width={24}
              height={30}
              src={Logo.src}
              alt={"BlossmLogoSmall"}
              loader={customLoader}
              objectFit={"fill"}
            />
            <Typography variant={"h6"} fontWeight={"bold"}>
              Reel Post Guide
            </Typography>
          </DocHeader>
          <DocBody>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What is a Reel Post promo?
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              On Instagram, the seller posts a reel (90 second short-form video)
              provided by the buyer that includes captions and hashtags.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Promo is complete when
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The reel has been posted on the seller's feed for the set amount
              of time.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Buyer is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing a premade short-form (90 second) video with captions and
              desired hashtags.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Communicating with the seller to receive confirmation.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Seller is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Notifying the buyer the post/story has been shared and/or
              directing them to the story.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing verification that the story was visible for the entire
              duration of the promo.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What happens if the promo is not completed?
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              If the reel was not posted for the entire duration of the promo,
              or was not shared to Instagram as advertised, the buyer may
              dispute the promo.
            </Typography>
            <Button
              onClick={() => close()}
              fullWidth
              sx={{
                background: theme.palette.primary.main,
                color: Colors.white,
                borderRadius: "24px",
              }}
            >
              Got it!
            </Button>
          </DocBody>
        </>
      )}
      {promoGuide === "Repost" && (
        <>
          <DocHeader>
            <Image
              width={24}
              height={30}
              src={Logo.src}
              alt={"BlossmLogoSmall"}
              loader={customLoader}
              objectFit={"fill"}
            />
            <Typography variant={"h6"} fontWeight={"bold"}>
              Repost Guide
            </Typography>
          </DocHeader>
          <DocBody>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What is a Repost promo?
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The seller shares the buyer's original post on a certain platform
              to their own feed.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Promo is complete when
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The seller has kept the repost on their feed for the entire
              duration of the promo.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Buyer is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing content/media, captions, and links.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Communicating with the seller to receive confirmation.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Seller is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Notifying the buyer the post has been reposted and/or directing
              them to the repost.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing verification that the post was reposted on their feed
              for the entire duration of the promo.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What happens if the promo is not completed?
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              If the post was not reposted for the entire duration of the promo
              or was not reposted to the platform as advertised, the buyer may
              dispute the promo.
            </Typography>
            <Button
              onClick={() => close()}
              fullWidth
              sx={{
                background: theme.palette.primary.main,
                color: Colors.white,
                borderRadius: "24px",
              }}
            >
              Got it!
            </Button>
          </DocBody>
        </>
      )}

      {promoGuide === "Assistant Services" && (
        <>
          <DocHeader>
            <Image
              width={24}
              height={30}
              src={Logo.src}
              alt={"BlossmLogoSmall"}
              loader={customLoader}
              objectFit={"fill"}
            />
            <Typography variant={"h6"} fontWeight={"bold"}>
              Assistant Services Guide
            </Typography>
          </DocHeader>
          <DocBody>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What is an Assistant Services promo?
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              On Twitter/X, the seller acts as an “assistant” by sharing the
              buyer's page in multiple group messages, and these shares are
              often referred to as "drops". The members of each group will
              repost content from the buyer's page to their own. The number of
              reposts per group depends on the group type, but typically the
              buyer’s content will be reposted 3 or 4 times for each drop. For
              example, a Twitter/X assistant offering 1 drop per day in 100
              group messages for one week means the buyer receives:
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              100 drops per day (1 drop x 100 groups)
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              700 total drops for the entire promo (100 drops/day x 7 days)
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              ~2,100 reposts resulting from the promo (~3 reposts x 700 total
              drops)
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Promo is complete when
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The seller has made the correct number of "drops" to the specified
              groups over the requested duration of services.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Buyer is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Verifying the the number and size of the group messages matches
              the promo description.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Preparing their Twitter/X page with updated content they would
              like to be shared.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Seller is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Notifying the buyer when the services begin and end.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Maintaining records of the “drops” and regularly provide the buyer
              with screenshots/recordings.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What happens if the promo is not completed?
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              If the services were not provided for the entire duration of the
              promo or were not carried out as advertised, the buyer may dispute
              the promo.
            </Typography>
            <Button
              onClick={() => close()}
              fullWidth
              sx={{
                background: theme.palette.primary.main,
                color: Colors.white,
                borderRadius: "24px",
              }}
            >
              Got it!
            </Button>
          </DocBody>
        </>
      )}
      {promoGuide === "Professional Services" && (
        <>
          <DocHeader>
            <Image
              width={24}
              height={30}
              src={Logo.src}
              alt={"BlossmLogoSmall"}
              loader={customLoader}
              objectFit={"fill"}
            />
            <Typography variant={"h6"} fontWeight={"bold"}>
              Professional Services Guide
            </Typography>
          </DocHeader>
          <DocBody>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What is a Professional Services promo?
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The seller offers their expertise based on their specialization
              and qualifications. This includes photography/videography,
              hair/makeup artistry, media editing, and other service areas.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Promo is complete when
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The Services are complete once the buyer and seller have successfully
              carried out the collaboration by meeting all of the expectations
              hat were clearly defined prior to the provision of services. If
              applicable, any content created directly from the offer has been
              shared with the buyer.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Buyer is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Communicating with the seller prior to purchase to ensure all
              of the service details are clearly defined and understood.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Coordinating any logistics, such as scheduling dates and locations,
              for the services to be provided.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Being responsive and cooperative during the delivery of the Services,
              including arriving on time at the agreed-upon date and location.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Seller is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Clearly and accurately describing the details of the Services being
              offered in the collaboration and what content will be created and shared.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
                Coordinating any logistics, such as scheduling dates and locations, for the services to be provided.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing the Services as described and agreed upon by the buyer and,
              if applicable, providing any content from the collaboration to the buyer.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What happens if the promo is not completed?
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              If the Services are not carried out according to the explicitly agreed-upon
              details of the collaboration, the buyer may dispute the promo.
            </Typography>
            <Button
              onClick={() => close()}
              fullWidth
              sx={{
                background: theme.palette.primary.main,
                color: Colors.white,
                borderRadius: "24px",
              }}
            >
              Got it!
            </Button>
          </DocBody>
        </>
      )}
      {promoGuide === "Mention" && (
        <>
          <DocHeader>
            <Image
              width={24}
              height={30}
              src={Logo.src}
              alt={"BlossmLogoSmall"}
              loader={customLoader}
              objectFit={"fill"}
            />
            <Typography variant={"h6"} fontWeight={"bold"}>
              Mention Guide
            </Typography>
          </DocHeader>
          <DocBody>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What is a Mention promo?
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              On YouTube, the seller tags the buyer's Youtube channel in a video
              description or title as a Mention.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Promo is complete when
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The buyer's channel is mentioned in the video description or title
              for the entire duration of the promo.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Buyer is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Meeting the minimum number of subscribers required by YouTube in
              order to be mentioned.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Communicating with the seller to receive confirmation.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Seller is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Notifying the buyer the Mention has been made and/or directing
              them to the video.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing verification that the Mention was active on their video
              for the entire duration of the promo.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What happens if the promo is not completed?
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              If the buyer’s channel was not mentioned for the entire duration
              of the promo or was not mentioned on YouTube as advertised, the
              buyer may dispute the promo.
            </Typography>
            <Button
              onClick={() => close()}
              fullWidth
              sx={{
                background: theme.palette.primary.main,
                color: Colors.white,
                borderRadius: "24px",
              }}
            >
              Got it!
            </Button>
          </DocBody>
        </>
      )}
      {promoGuide === "Short" && (
        <>
          <DocHeader>
            <Image
              width={24}
              height={30}
              src={Logo.src}
              alt={"BlossmLogoSmall"}
              loader={customLoader}
              objectFit={"fill"}
            />
            <Typography variant={"h6"} fontWeight={"bold"}>
              Short Guide
            </Typography>
          </DocHeader>
          <DocBody>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What is a Shorts promo?
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              On YouTube, the seller posts a Short (60 second short-form video)
              to their channel containing the media, captions, and/or links
              provided by the buyer.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Promo is complete when
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The Short has been posted on the seller's channel for the set
              amount of time.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Buyer is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing a premade short-form (60 second) video with captions and
              links.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Communicating with the seller to receive confirmation.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Seller is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Notifying the buyer the Short has been posted and/or directing
              them to the Short.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing verification that the Short was visible for the entire
              duration of the promo.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What happens if the promo is not completed?
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              If the Short was not posted for the entire duration of the promo,
              or was not posted to YouTube as advertised, the buyer may dispute
              the promo.
            </Typography>
            <Button
              onClick={() => close()}
              fullWidth
              sx={{
                background: theme.palette.primary.main,
                color: Colors.white,
                borderRadius: "24px",
              }}
            >
              Got it!
            </Button>
          </DocBody>
        </>
      )}
      {promoGuide === "Friend Spot" && (
        <>
          <DocHeader>
            <Image
              width={24}
              height={30}
              src={Logo.src}
              alt={"BlossmLogoSmall"}
              loader={customLoader}
              objectFit={"fill"}
            />
            <Typography variant={"h6"} fontWeight={"bold"}>
              Friend Spot Guide
            </Typography>
          </DocHeader>
          <DocBody>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What is a Friend Spot promo?
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The seller offers a spot on their OnlyFans public friends list,
              where the buyer’s profile is visible to the seller’s fans.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Promo is complete when
            </Typography>
            <Typography variant={"body1"} sx={{ mb: 2 }}>
              The buyer remains added to the seller’s OnlyFans public
              friends list for the entire duration of the promo.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Buyer is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Communicating with the seller to receive confirmation.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Verifying they are actively on the friends list for
              the duration of the promo.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              The Seller is responsible for
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Setting the OnlyFans friends list to “public.”
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Notifying the buyer when they have been added as a friend.
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              <Dot />
              Providing verification that the buyer remained on the
              friends list for the agreed upon duration.
            </Typography>
            <Typography variant={"body1"} fontWeight={"bold"} sx={{ mb: 2 }}>
              What happens if the promo is not completed?
            </Typography>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", gap: "8px", mb: 2 }}
            >
              If the buyer did not remain on the seller’s public friends list for the
              entire duration of the promo, the buyer may dispute the promo.
            </Typography>
            <Button
              onClick={() => close()}
              fullWidth
              sx={{
                background: theme.palette.primary.main,
                color: Colors.white,
                borderRadius: "24px",
              }}
            >
              Got it!
            </Button>
          </DocBody>
        </>
      )}
    </Dialog>
  )
}

const Dot = styled.span`
  min-width: 5px;
  min-height: 5px;
  max-height: 5px;
  background: #1f1f1f;
  display: block;
  margin-top: 6px;
`

const DocHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px 16px 24px;
`

const DocBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px 16px 24px;
`
