export enum OrderStatusStates {
  None = "",
  New = "new",
  Pending = "pending",
  Timeout = "timeout",
  ResubmitPayment = "resubmit_payment",
  Cancelled = "cancelled",
  Accepted = "accepted",
  Rejected = "rejected",
  InProgress = "in progress",
  Expired = "expired",
  SellerCompleted = "seller completed",
  SellerRefund = "seller refund",
  AutoRejected = "auto rejected",
  Completed = "completed",
  PartialRefund = "partial_refund",
  Disputed = "disputed",
  UnderReview = "under_review",
  Reviewed = "reviewed",
}
export const OrderStatusLabels = (
  skipBuyerConfirmation: boolean
): Map<OrderStatusStates, string> => {
  return new Map([
    [OrderStatusStates.None, "Placing Order"],
    [OrderStatusStates.Pending, "Pending Acceptance"],
    [OrderStatusStates.Accepted, "Scheduled"],
    [OrderStatusStates.InProgress, "In Progress"],
    [OrderStatusStates.Expired, "Expired"],
    [
      OrderStatusStates.SellerCompleted,
      skipBuyerConfirmation
        ? "Promo Completed"
        : "Waiting For Buyer Confirmation",
    ],
    [OrderStatusStates.Completed, "Confirmed Complete"],
    [OrderStatusStates.PartialRefund, "Confirmed Complete w/Partial Refund"],
    [OrderStatusStates.Reviewed, "Complete and Reviewed"],
  ])
}

export const getOrderSplashLabel = (
  skipBuyerConfirmation: boolean
): Map<OrderStatusStates, string> => {
  return new Map([
    [OrderStatusStates.None, "Placing Order"],
    [OrderStatusStates.Pending, "Pending Acceptance"],
    [OrderStatusStates.Accepted, "Scheduled"],
    [OrderStatusStates.InProgress, "In Progress"],
    [OrderStatusStates.Expired, "Expired"],
    [
      OrderStatusStates.SellerCompleted,
      skipBuyerConfirmation
        ? "Promo Completed"
        : "Waiting For Buyer Confirmation",
    ],
    [OrderStatusStates.Completed, "Confirmed Complete"],
    [OrderStatusStates.PartialRefund, "Confirmed Complete w/Partial Refund"],
    [OrderStatusStates.Disputed, "Disputed"],
    [OrderStatusStates.UnderReview, "Under Review"],
    [OrderStatusStates.Reviewed, "Complete and Reviewed"],
  ])
}

export enum PartialRefundSteps {
  None = "none",
  Initiated = "initiated",
  InProgress = "inProgress",
  ManualEntry = "manualEntry",
  Submitting = "submitting",
  Completed = "completed",
}

export enum PovStates {
  buyer = "Buyer",
  seller = "Seller",
}

export const upperBodyButtonStack = {
  "display": "flex",
  "flexDirection": "column",
  "gap": "8px",
  "marginTop": "24px",
}
