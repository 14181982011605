import {
  alpha,
  Box,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"
import { Group, Groups } from "./GroupsLayout"
import React, { useState } from "react"
import PackagesRadioGroup from "../../Atoms/Promo/PackagesRadioGroup"
import {
  ImageOutlined,
  LinkOutlined,
  SecurityOutlined,
  Star,
  TimerOutlined,
} from "@mui/icons-material"
import Grid from "@mui/material/Unstable_Grid2"
import { getPromoType, IPromoType } from "../../Atoms/Promo/Constants/Promos"
import { IPromoTier } from "./CreatePromo"
import { Colors } from "../../../styles/Colors"
import {
  LinkRequirement,
  MediaContentType,
  MediaRequirement,
} from "../../Atoms/Promo/PromoRequirements"
import { PromoGuide } from "../../Atoms/Promo/PromoGuide"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import styled from "@emotion/styled"
import { getRoleSubtitle, roleOptions } from "../../Atoms/ProfileRoleBadge"
import { IRole } from "../../Organisms/Settings/ChangeRole"

interface IPromoPreview {
  packages: IPromoTier[]
  promoTitle: string
  promoDescription: string
  numSlots: number
  platform: string
  mediaRequirement: MediaRequirement
  mediaContentType: MediaContentType
  linkRequirement: LinkRequirement
  customRequirement1: string
  customRequirement2: string
  customRequirement3: string
  availability: IRole[]
}

interface IPromoPreviewHeader {
  promoTitle: string
  promoDescription: string
  promoAvailabilityText: string
  promoType: string
}
const PromoPreviewHeader = ({
  promoDescription,
  promoTitle,
  promoAvailabilityText,
  promoType,
}: IPromoPreviewHeader): JSX.Element => {
  const theme = useTheme()
  const [infoPromo, setInfoPromo] = useState<string>()
  const [infoOpen, setInfoOpen] = useState<boolean>(false)

  function openPromoGuide(promoType: string): void {
    setInfoPromo(promoType)
    setInfoOpen(true)
  }

  return (
    <Stack sx={{ width: "100%" }}>
      <Box>
        <PromoGuide
          promoGuideOpen={infoOpen}
          close={() => setInfoOpen(false)}
          promoGuide={infoPromo}
        />
        <ControlBar>
          <Typography variant={"h6"} fontWeight={700}>
            {promoTitle}
          </Typography>
          <IconButton
            onClick={() => openPromoGuide(promoType)}
            sx={{
              "&:hover": {
                backgroundColor: alpha(theme.palette.text.secondary, 0.4),
              },
            }}
          >
            <InfoOutlinedIcon sx={{ color: theme.palette.text.secondary }} />
          </IconButton>
        </ControlBar>
      </Box>
      <Box pb={promoDescription ? "16px" : "0px"}>
        <Typography color={theme.palette.text.secondary}>
          {promoAvailabilityText}
        </Typography>
      </Box>
      <Box pb={"16px"} sx={{ overflowWrap: "break-word" }}>
        <Typography>{promoDescription}</Typography>
      </Box>
    </Stack>
  )
}

type PromoDurationProps = Partial<IPromoPreviewFooter> & {
  useShorthand: boolean
}

export const PromoDuration = ({
  promo,
  duration,
  duration_type,
  useShorthand,
}: PromoDurationProps): JSX.Element => {
  if (!promo) {
    return <></>
  }
  const theme = useTheme()
  return (
    <Stack direction={"row"}>
      <Grid
        xs={"auto"}
        sx={{
          display: "flex",
          alignItems: "flex-start",
          paddingRight: "4px",
        }}
      >
        <TimerOutlined htmlColor={Colors.action.active.light} />
      </Grid>
      <Grid sx={{ alignSelf: "center" }}>
        <Typography variant={"subtitle2"} color={theme.palette.text.secondary}>
          {promo.getDurationDescription(
            Number(duration),
            duration_type || "",
            useShorthand
          )}
        </Typography>
      </Grid>
    </Stack>
  )
}

interface IPromoPreviewFooter {
  duration: string | undefined
  promo: IPromoType
  duration_type: string
  mediaRequirement?: MediaRequirement
  mediaContentType?: MediaContentType
  linkRequirement?: LinkRequirement
  customRequirement1?: string
  customRequirement2?: string
  customRequirement3?: string
}
export const PromoPreviewFooter = ({
  duration_type,
  promo,
  duration,
  mediaRequirement,
  mediaContentType,
  linkRequirement,
  customRequirement1,
  customRequirement2,
  customRequirement3,
}: IPromoPreviewFooter): JSX.Element => {
  const theme = useTheme()

  const getMediaRequirements = (): string => {
    switch (`${mediaRequirement}-${mediaContentType}`) {
      case `${MediaRequirement.Optional}-${MediaContentType.NonNude}`:
        return "Non-nude media allowed"
      case `${MediaRequirement.Required}-${MediaContentType.NonNude}`:
        return "Requires non-nude media"
      case `${MediaRequirement.Required}-${MediaContentType.Nude}`:
        return "Requires media"
      case `${MediaRequirement.Optional}-${MediaContentType.Nude}`:
      default:
        return ""
    }
  }

  const getLinkRequirements = (): string => {
    switch (linkRequirement) {
      case LinkRequirement.FreeTrial:
        return "Requires Free Trial link"
      case LinkRequirement.CampaignPaid:
        return "Requires Paid Campaign link"
      case LinkRequirement.CampaignFree:
        return "Requires Free Campaign link"
      case LinkRequirement.None:
      default:
        return ""
    }
  }

  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid
        xs={"auto"}
        sx={{
          display: "flex",
          alignItems: "flex-start",
          paddingRight: "4px",
        }}
      >
        <SecurityOutlined htmlColor={Colors.action.active.light} />
      </Grid>
      <Grid sx={{ alignSelf: "center" }}>
        <Typography variant={"subtitle2"} color={theme.palette.text.secondary}>
          Payment released upon promo completion
        </Typography>
      </Grid>

      <Grid sx={{ height: "10px" }} xs={12} />
      <PromoDuration
        promo={promo}
        duration={duration}
        duration_type={duration_type}
        useShorthand={false}
      />

      {getMediaRequirements() && (
        <>
          <Grid sx={{ height: "10px" }} xs={12} />
          <Grid
            xs={"auto"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              paddingRight: "4px",
            }}
          >
            <ImageOutlined htmlColor={"#0000008A"} />
          </Grid>
          <Grid sx={{ alignSelf: "center" }}>
            <Typography
              variant={"subtitle2"}
              color={theme.palette.text.secondary}
            >
              {getMediaRequirements()}
            </Typography>
          </Grid>
        </>
      )}

      {getLinkRequirements() && (
        <>
          <Grid sx={{ height: "10px" }} xs={12} />
          <Grid
            xs={"auto"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              paddingRight: "4px",
            }}
          >
            <LinkOutlined htmlColor={"#0000008A"} />
          </Grid>
          <Grid sx={{ alignSelf: "center" }}>
            <Typography
              variant={"subtitle2"}
              color={theme.palette.text.secondary}
            >
              {getLinkRequirements()}
            </Typography>
          </Grid>
        </>
      )}

      {customRequirement1?.trim() && (
        <>
          <Grid sx={{ height: "10px" }} xs={12} />
          <Grid
            xs={"auto"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              paddingRight: "4px",
            }}
          >
            <Star htmlColor={"#0000008A"} />
          </Grid>
          <Grid sx={{ alignSelf: "center", maxWidth: "90%" }}>
            <Typography
              variant={"subtitle2"}
              color={theme.palette.text.secondary}
            >
              {`Custom Requirement: ${customRequirement1}`}
            </Typography>
          </Grid>
        </>
      )}

      {customRequirement2?.trim() && (
        <>
          <Grid sx={{ height: "10px" }} xs={12} />
          <Grid
            xs={"auto"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              paddingRight: "4px",
            }}
          >
            <Star htmlColor={"#0000008A"} />
          </Grid>
          <Grid sx={{ alignSelf: "center", maxWidth: "90%" }}>
            <Typography
              variant={"subtitle2"}
              color={theme.palette.text.secondary}
            >
              {`Custom Requirement: ${customRequirement2}`}
            </Typography>
          </Grid>
        </>
      )}

      {customRequirement3?.trim() && (
        <>
          <Grid sx={{ height: "10px" }} xs={12} />
          <Grid
            xs={"auto"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              paddingRight: "4px",
            }}
          >
            <Star htmlColor={"#0000008A"} />
          </Grid>
          <Grid sx={{ alignSelf: "center", maxWidth: "90%" }}>
            <Typography
              variant={"subtitle2"}
              color={theme.palette.text.secondary}
            >
              {`Custom Requirement: ${customRequirement3}`}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  )
}

interface IPromoCard {
  promoTitle: string
  promoDescription: string
  numSlots: number
  selectedPackage: IPromoTier
  setSelectedPackage: (pkg: IPromoTier) => void
  packages: IPromoTier[]
  platform: string
  PromoPackageButton: JSX.Element
  promoAvailabilityText: string
  mediaRequirement: MediaRequirement
  mediaContentType: MediaContentType
  linkRequirement: LinkRequirement
  customRequirement1: string
  customRequirement2: string
  customRequirement3: string
  isListItem?: boolean
  lastInList?: boolean
}
export const PromoCard = ({
  platform,
  promoDescription,
  promoTitle,
  selectedPackage,
  setSelectedPackage,
  packages,
  PromoPackageButton,
  promoAvailabilityText,
  mediaRequirement,
  mediaContentType,
  linkRequirement,
  customRequirement1,
  customRequirement2,
  customRequirement3,
  isListItem = false,
  lastInList = false,
}: IPromoCard): React.JSX.Element => {
  const promo = getPromoType(platform, promoTitle)

  if (promo?.getDurationDescription === undefined) {
    return <></>
  }
  return (
    <Group
      isMobile={true}
      vPadding={"16px"}
      centerContent={false}
      applyDropShadow={lastInList || (!lastInList && !isListItem)}
      listItem={isListItem}
      lastInList={lastInList}
    >
      <PromoPreviewHeader
        promoDescription={promoDescription}
        promoTitle={promoTitle}
        promoAvailabilityText={promoAvailabilityText}
        promoType={promo.type}
      />
      <PackagesRadioGroup
        promoTitle={promoTitle}
        selectedPackage={selectedPackage}
        setSelectedPackage={setSelectedPackage}
        promoPackages={packages}
        platform={platform}
      />
      <PromoPreviewFooter
        promo={promo}
        duration={selectedPackage?.duration || "0"}
        duration_type={
          selectedPackage?.duration_type ||
          promo.durationValues[promo.durationValues.length - 1]
        }
        mediaRequirement={mediaRequirement}
        mediaContentType={mediaContentType}
        linkRequirement={linkRequirement}
        customRequirement1={customRequirement1}
        customRequirement2={customRequirement2}
        customRequirement3={customRequirement3}
      />
      {PromoPackageButton}
    </Group>
  )
}

const PromoPreview = ({
  packages,
  promoTitle,
  promoDescription,
  numSlots,
  platform,
  mediaRequirement,
  mediaContentType,
  linkRequirement,
  customRequirement1,
  customRequirement2,
  customRequirement3,
  availability,
}: IPromoPreview): React.JSX.Element => {
  const [selectedPackage, setSelectedPackage] = useState<IPromoTier>({
    packageId: packages[0].packageId,
    price: packages[0].price,
    quantity: packages[0].quantity,
    duration: packages[0].duration,
    duration_type: packages[0].duration_type,
  })
  const promoAvailabilityText =
    availability.length === 1
      ? getRoleSubtitle(availability[0].role as roleOptions)
      : "Available to Everyone"

  return (
    <Groups isMobile={true} useColumnLayout={true} centerContent={false}>
      <Group isMobile={true} vPadding={"16px"} centerContent={false}>
        <Typography variant={"body1"}>
          This is how visitors to your profile will see your {promoTitle + " "}
          promo. Check to make sure that all details are correct.
        </Typography>
      </Group>

      <PromoCard
        platform={platform}
        promoDescription={promoDescription}
        promoTitle={promoTitle}
        numSlots={numSlots}
        packages={packages}
        selectedPackage={selectedPackage}
        setSelectedPackage={setSelectedPackage}
        promoAvailabilityText={promoAvailabilityText}
        PromoPackageButton={<></>}
        mediaRequirement={mediaRequirement}
        mediaContentType={mediaContentType}
        linkRequirement={linkRequirement}
        customRequirement1={customRequirement1}
        customRequirement2={customRequirement2}
        customRequirement3={customRequirement3}
      />
    </Groups>
  )
}

export default PromoPreview

const ControlBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
